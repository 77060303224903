@media (max-width: 768px) {
  #html5qr-code-full-region{
    height: 80vh !important;
    width: 100vw;
  }
  #html5qr-code-full-region__scan_region {
    height: 70vh !important;
    position: relative !important;
    overflow: hidden;
  }
  #html5qr-code-full-region video {
    width: 100vw;  
    height: 80vh !important;  
    object-fit: cover !important; 
   position: absolute !important;
   /* z-index: 100 !important; */
   aspect-ratio: 1/3 !important;
  }

  #html5qr-code-full-region .qrbox{
    /* height: 100vh !important; */
  }
  .scanner-container{
    /* height: 100vh !important; */
    position: relative;
    min-height: 80vh;
    overflow: hidden;
  }
  #html5qr-code-full-region__dashboard{
    position: relative;
    bottom: 100px;
    z-index: 1000;
  }

  .MuiDialogContent-root .ss-ypiqx9-MuiDialogContent-root{
    height: 100vh !important;
  }
#qr-shaded-region{
  /* border-width: 350px 80px !important; */
  border-top-width: 100px !important;
  border-bottom-width: 270px !important;
  border-left-width: 50px !important;
  border-right-width: 50px !important;
  height: 80vh !important;

}

}

#html5qr-code-full-region {
  position: relative;
  width: 350px;  /* Adjusted width to 350px as per the comment */
  height: 400px;  /* Adjusted height to 700px to maintain aspect ratio 1:2 */
  overflow: hidden;  /* Ensures the content stays within rounded corners */
}



#html5qr-code-full-region video {
  width: 100%;  
  height: 400px;  
  object-fit: cover; 
}



#html5qr-code-full-region .qrbox {
  position: absolute;
  border: 2px solid #00ff00;  /* Green border to highlight the scanning area */
  border-radius: 50px !important;  /* Apply border-radius */
  width: 100%;
  height: 400px;
  top: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;  /* Prevents the box from blocking interaction with the video */
  overflow: scroll;  /* Ensures the content inside stays clipped to the rounded box */
}

.scanner-container {
  position: relative;
  display: inline-block;
  height: 400px;  /* Match the height of the main container */
}

.red-line {
  position: absolute;
  filter: blur(2px);  /* Apply blur effect to the red line */
  width: 100%; /* Line width */
  height: 2px; /* Height of the red line */
  background-color: red;
  animation: moveLine 3s infinite ease-in-out; /* Apply animation */
}

.pulse{
  animation: pulse 1s infinite ease-in-out;
}



/* The animation for moving the line up and down */
@keyframes moveLine {
  0% {
    top: 0;
  }
  50% {
    top: 100%; /* Move down */
  }
  100% {
    top: 0; /* Move back up */
  }
}

@keyframes pulse {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.05);
  }
  100%{
    transform: scale(1);
  }
}

#qr-shaded-region {
  height: 400px; 
  width: 100%;
  margin: 5px !important; /* Add margin */
  border-color:rgba(0, 0, 0, 0.15) !important;
}

#html5qr-code-full-region__dashboard_section_csr {
  display: flex;  /* Hide the dashboard section */
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 80px;
  
}
#html5qr-code-full-region__scan_region {
  height: 400px;
}
/* Remove unnecessary height and margin-top that were incorrectly written */
#html5qr-code-full-region {
  border: 0 !important;  /* Remove border */
  margin-top: 50px !important;  /* Corrected margin-top value */
  height: 400px;
}

#qr-shaded-region > div{
  background-color: #2d1ade !important;
}

#html5qr-code-full-region__scan_region #qr-canvas{
  z-index: 1000;
}
