@media (max-width: 768px) {
  .form-container{
    max-width: 80vw;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 40px; */
  /* height: 100vh; */
  /* background-color: #f0f2f5; */
}

.form-container {
  width: 500px;
  padding: 30px;
  
  /* background-color: #fff; */
  /* border-radius: 10px; */
  box-shadow: 0 2px 4px rgba(249, 247, 247, 0.1);
}

.logo-container {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-bottom: 30px;

}

.logo {
  width: 100px;
  height: 80px;
  font-size: 48px;
  color: #1877f2;
}

.logo-text {
  text-align: start;
  font-size: 40px;
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
  /* color: #000; */
}

.form {
  display: flex;
  flex-direction: column;
  /* gap: "15px"; */
}

.input {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #dadde1;
  border-radius: 5px;
  font-size: 14px;
}
.password-input {
  width: 100%; /* or adjust the width as needed */
  margin-bottom: 16px; /* to match your .input class margin */
  margin-top: 10px;
}

.button {
  padding: 15px 15px ;
  margin-top: 10px;
  margin-bottom: 60px;
  border: none;
  border-radius: 5px;
  background-color: #1877f2;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
}

.switch-button {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  color:white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

}

.switch-button:hover {
  text-decoration: underline;
}
